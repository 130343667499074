.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #383e58;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.container {
  background-color: white;
  height: 100%;
  border: 1px solid #a0a0a0;
}

.contentWrap {
  max-width: 2000px;
  padding: 10px 30px 10px 30px;
}

.line {
  height: 1px;
  border-bottom: 1px solid #eee;
}

.select {
  width: 100%;
  height: 40px;
}

.radioWrap {
  background-color: #f5f6fb;
  padding: 15px;
}

.categoryWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fb;
  padding: 10px 15px;
  gap: 10px;
  cursor: pointer;
}
.categoryWrap.selected {
  background-color: #383e58;
  color: white;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
}

.categoryModalBtn {
  width: max-content;
  border: 1px solid #aaa;
  padding: 5px 10px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

.thumbnailHandle {
  cursor: pointer;
}

.titleArea {
  background-color: #e7e7e7;
  padding: 10%;

  display: flex;
  justify-content: flex-end;
}

.contentArea {
  padding: 10px;
  width: 75%;
}
