.cartProductContainer {
  background-color: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 20px 30px;
}

.border {
  width: 100%;
  padding: 0.5px;
  background-color: #ddd;
  margin-top: 15px;
  margin-bottom: 15px;
}
.allCheckBox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.seller {
  display: flex;
}
.sellerContact {
  padding-left: 20px;
  font-size: 14px;
  line-height: 23px;
}

.sellerName {
  font-size: 14px;
  line-height: 23px;
}
.coupon {
  font-size: 14px;
  color: #2e9cc3;
  font-weight: bold;
}

.productImg {
  width: 100px;
  height: 100px;
  background-image: url("https://picsum.photos/id/311/100/100");
  cursor: pointer;
}

.productName {
  font-size: 14px;
}

.inputBox {
  width: 20px;
  height: 20px;
  margin-top: 40px;
}

.option {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.optionTitle {
  font-size: 14px;
  font-weight: bold;
}

.optionContent {
  font-size: 12px;
  line-height: 20px;
  padding-left: 3px;
}
.color {
  width: 14px;
  height: 14px;
  background-color: salmon;
  border-radius: 50%;
  margin-top: 3px;
  margin-left: 5px;
  margin-right: 15px;
}

.deliveryForm {
  display: flex;
  font-size: 13px;
  cursor: pointer;
}

.deliveryPrice {
  color: #2e9cc3;
}
.priceForm {
  display: flex;
  font-size: 12px;
}
.realPrice {
  text-decoration: line-through;
  padding-right: 15px;
}
.sale {
  background-color: pink;
  opacity: 0.7;
  border-radius: 3px;
  color: red;
  padding: 1px 3px 1px 2px;
  line-height: 11px;
}
.iconForm {
  display: flex;
  flex-direction: row-reverse;
}
.wishIcon {
  margin-right: 16px;
  cursor: pointer;
}
.deleteIcon {
  cursor: pointer;
}
.button {
  line-height: 17px;
  padding: 8px 20px 8px 20px;
  width: 200px;
  height: 20px;
  background-color: #ff4747;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  flex-direction: row-reverse;
  padding-top: 20px;
  font-size: 14px;
}
.titleWrap{
  padding-left: 10px;
}