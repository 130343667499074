.wrap{
    width : 100% ;
    height: 100%;
}
.sticky {
    position: sticky;
    top:0;
    z-index: 1000;
}

.container {
    position: relative;
}

.bgWrap{
    width : 100% ;
    background-image: url(/src/resources/img/partnersBg.png);
    background-size: cover;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.footerWrap{
    padding: 50px ;
    width : 100% ;
    background-image: url(/src/resources/img/partnersBg.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color : white ;
}

.mobileFooterWrap{
    padding: 50px ;
    width : 100% ;
    background-image: url(/src/resources/img/partnersBg.png);
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color : white ;
}

.buttonWrap{
    width : 100px ;
    
}

.bottomButton{
    width : 240px ;
    
}

.pointBox{
    border: 2px solid #ddd;
    height: 300px;
    background-color: white;
    border-radius: 15px;
}

.star1{
    position: absolute;
    top : 10% ;
    left : 33% ;
    
}

.star2{
    position: absolute;
    top : 65% ;
    right : 33% ;
    
}

.mobileStar1{
    position: absolute;
    top : 10% ;
    left : 10% ;
    
}

.mobileStar2{
    position: absolute;
    top : 65% ;
    right : 20% ;
    
}

.footerLogo{
    width : 150px ;
}