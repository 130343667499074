.topArea {
    overflow: hidden;
    position: relative;
}

.flagTop {
    position: absolute;
    transform: translateY(50%);
    bottom: 0;
}

.shadow {
    position: absolute;
    border-top-left-radius: 100%;
    border-top-right-radius: 100%;
    width: 10%;
}

.shadow.left {
    left: 0;
    transform: translateX(-50%);
}

.shadow.right {
    right: 0;
    transform: translateX(50%);
}

.flagArea {
    position: relative;
    background-color: white;
}

.flagBody {
    position: absolute;
    top: 0;
    height: 60%;
    z-index: 2;
}


.flagTail {
    position: absolute;
    top: 60%;
    width: 0;
    z-index: 1;
}

.titleArea {
    
}