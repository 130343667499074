.wrap {
    overflow: hidden;
    background-color: white;
    border-bottom: 1.5px solid var(--searchBar-color);
    width: 94%;
    /* border:1.5px solid var(--searchBar-color); */
}

.input {
    width:100%;
    height:40px;
    padding: 10px 0px;
    font-size: 15px;
}

.icon {
    background-color: white;
    color :var(--main-color);
    cursor: pointer;
    font-size: 20px;
    height: 100%;
}

