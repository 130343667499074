.label{
    display: flex;
    align-items : center;
    justify-content: center;
    width:100%;
    height:100%;
    background-color: #383E58;
    color: white;
    padding : 10px 15px; 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.container{
    background-color: white;
    height: 100%;
    
}



.table {
    width: 100%;
    border-collapse: collapse;
    padding : 20px ;
    background-color: white;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #5471e6;
    color: white;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    
}

.button {
    background-color: #5471e6;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    border-radius: 5px;
}


.deleteIconArea {
    background-color: #383E58;
    padding: 1px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
}

.managementArea {
    background-color: white;
    border: 1px solid black;
    padding: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
    border-radius: 3px;
    cursor: pointer;
}