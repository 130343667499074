.container {
    position: relative;
    display: flex;
    gap: 20px;
    padding: 20px;
    min-width: 1200px;
  }
  
  .mainContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
  .sidePanel {
    width: min(350px, 30%);
    position: sticky;
    top: 100px;
    align-self: flex-start;
  }
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      min-width: inherit;
      padding: 10px;
    }
  
    .sidePanel {
      width: 100%;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      background: white;
      padding: 10px;
      box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }
  }