.selectContainer {
    position: relative;
    width: 100%;
    font-family: "Arial", sans-serif;
  }
  
  .selectHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    
    border-radius: 5px;
    background-color: #fff;
    cursor: pointer;
    color: #aaa;
  }
  
  .arrow {
    font-size: 12px;
    color: #aaa;
  }
  
  .selectList {
    position: absolute;
    top: 130%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 0; /* 드롭다운 리스트는 둥근 테두리 제거 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .selectItem {
    padding: 10px 15px;
    cursor: pointer;
    color: #aaa;
  }
  
  .selectItem:hover {
    background-color: #f1f1f1;
  }
  