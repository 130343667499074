.columns {
    height: 100%;
    float: right;
}

.column {
    height: 100%;
    float: left;
    cursor: pointer;
}

.column:hover {
    /* background-color: white; */
}

.itemTitle {
    display: inline-block;
    padding: 0px 5px;
    font-weight: bold;
}

.topBarItem {
    position: relative;
    font-size: var(--font-size-small);
    padding: 0px 10px;
    z-index: 1001;
}

.topBarItem:hover {
    color: var(--main-color);
    /* box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%); */
}
.topBarItem svg{
    font-size: var(--font-size-2xlarge);
}

.topBarItem:hover svg {
    color: var(--main-color);
  }

.subItemList {
    display: none;
}

.subItemList.show {
    color: #666;
    display: block;
    position: absolute;
    width: fit-content;
    height: max-content;
    top: 40px;
    right: -40px;
    padding: 0px 40px 40px 40px;
    cursor: default;
}

.subItemWrap {
    padding : 5px 0px 10px 0px;
    background-color: white;
    box-shadow: 0 5px 8px 0 rgb(0 0 0 / 10%);
}

.topBarFlag {
    width: 12px;
    display: inline-block;
}

.topBarSubItem {
    padding: 5px 20px;
    display: inline-block;
}

.topBarSubItem:hover {
    color: var(--main-color);
}

.topBarSubItemTitle {
    width: 70px;
    display: inline-block;
}

.topBarSubItemFlag {
    width: 12px;
    display: inline-block;
    padding-right: 10px;
}

.clickable {
    cursor: pointer;
}