.container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 10px;
}

.header {
    width: 100%;
    height: 100%;
    background-color: #B9CAF6;
}

.labelWrap{
    width: 100%;
    padding : 0 35px ;
    display: flex;
}