.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 0px;
  }
  
  .messageSection {
    margin-top: 20px;
  }
  
  .messageSection h3 {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }