.container {
    height:40px;
    border-bottom: 1px solid #eee;
    background-color: white;
    box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%);
}
.container.mobile {
    position : fixed;
    top:50px; 
    z-index: 999;
}

.menu {
    font-size: 17px;
    font-weight:700;
    cursor: pointer;
    transition: color 0.5s ease;
}

.menu:hover{
    color:var(--main-color);
}

.menu.active {
    color:var(--main-color);
}

.menu.mobile {
    font-size: 12px;
    cursor: pointer;
}


.scrollContainer {
    display: flex;
    overflow-x: auto; /* 가로 스크롤 활성화 */
    white-space: nowrap; /* 줄바꿈 방지 */
    -webkit-overflow-scrolling: touch; /* 모바일 부드러운 스크롤 */
    gap: 10px; /* 탭 간격 */
    padding: 15px 5px; /* 위아래 패딩 */
    width: 100%; /* 전체 너비 */
    position: fixed;
    box-sizing: border-box; /* 패딩과 너비 포함 */
    z-index: 999;
    background-color: white;
    box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%);
}

.scrollContainer::-webkit-scrollbar {
    display: none; /* 스크롤바 숨김 */
}

.menu {
    
    font-size: 14px;
    font-weight: 700;
    color: #666;
    cursor: pointer;
    
    transition: all 0.3s ease;
    text-align: center;
}

.tabWrapper {
    padding: 5px 15px;
    border: 1px solid #ddd;
    border-radius: 50px; /* 둥근 테두리 */
    background-color: #f5f5f5;
    transition: all 0.3s ease;
    display: inline-block; /* 가로 나열 */
    width: max-content;
}
.menu.active .tabWrapper {
    background-color: #3b82f6; /* 활성화된 배경색 */
    color: white;
    border: 1px solid #3b82f6;
}