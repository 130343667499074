.topButton {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 100px;
    left: 50%;
    z-index: 1000;
    border: 1px solid #e1e1e1;
    cursor: pointer;
    background-color: white;
}