/* App.css */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

/* Fade and Scale transition */
.fade-scale-enter {
  transform: scale(0.8);
  opacity: 0;
}
.fade-scale-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 200ms ease, opacity 200ms ease;
}
.fade-scale-exit {
  transform: scale(1);
  opacity: 1;
}
.fade-scale-exit-active {
  transform: scale(0.8);
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms ease;
}

/* Flip transition */
.flip-enter {
  transform: rotateY(90deg);
  opacity: 0;
}
.flip-enter-active {
  transform: rotateY(0);
  opacity: 1;
  transition: transform 200ms ease, opacity 200ms ease;
}
.flip-exit {
  transform: rotateY(0);
  opacity: 1;
}
.flip-exit-active {
  transform: rotateY(90deg);
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms ease;
}

/* Zoom transition */
.zoom-enter {
  transform: scale(0.9);
  opacity: 0;
}
.zoom-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 200ms ease, opacity 200ms ease;
}
.zoom-exit {
  transform: scale(1);
  opacity: 1;
}
.zoom-exit-active {
  transform: scale(0.9);
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms ease;
}

/* Slide transition */
.slide-enter {
  transform: translateX(100%);
}
.slide-enter-active {
  transform: translateX(0);
  transition: transform 200ms ease-in-out;
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 200ms ease-in-out;
}
