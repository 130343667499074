.productWrap{
    position: relative;
    background-color: white;
    display: flex;
    margin: 1px 0;
    padding : 10px ;
    box-shadow: 2px 2px 2px 0px rgb(0 0 0 / 10%);
}


.textColor{
    color : var(--font-color-disabled);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.wrap{
    border-bottom: 1px solid var(--font-color-disabled);    
    padding : 10px ;
    overflow: hidden;
}

.titleWrap{
    background-color: white;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
    padding : 15px ;
}

.inputWrap{
    max-width: 250px;
}


.contentWrap{
    
}

.title{
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    
}

.title:hover{
    
}

.tag{
    font-size: 14px;
    color:#6f6f6f;
}

.price{
    font-weight: bold;
    font-size: 18px;
}



.label{
    display: inline-block;
    font-size : 20px ;    
}

.checkboxArea {
}

.buttonWrap {
    position: absolute;
    top:10px;
    right:10px;
    width:20px;
    height:20px;
}

.imgWrap {
    
}

.button{
    width : 100%;
    height: 30px;
}