.wrap{
    height: 60vh;
    overflow-y: scroll;
    
}


.verticalLine{
    width: 1px;
    height: 100%;
    background-color: var(--main-color);
}

.horizontalLine{
    width: 100%;
    height: 1px;
    background-color: var(--line-color);
}
