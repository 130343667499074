.container {
    padding: 20px 0;
  }
  
  .container h2 {
    font-size: 14pt;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .paymentSelection {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    padding-top: 30px;
    padding-bottom: 20px;
    flex-direction: column;
    border-top: 3px solid #CECECE;
  }
  
  .radioOption {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .paymentMethod {
    padding: 5px;
    border-radius: 3px;
  }
  
  .methodInfo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    height: fit-content;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
  }