.card {
    width:100%;
    height:100%;
    position: relative;
    overflow: hidden;;
}


.delete{
    width: 35px;
    height: 35px;
    background-color: var(--main-color);
    position: absolute;
    top : 0 ;
    left : 40px ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.handle{
    right: 0;
}