.horizontalLine{
    width: 100%;
    height: 2px;
    background-color: #999;
}

.wrap{
    height: 60vh;
    overflow-y: scroll;
    
}