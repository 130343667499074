.container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
}
.container_outer {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border-right: 0;
}

.label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  /* height: 100%; */
  background-color: #383e58;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
