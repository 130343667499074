.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    height: 120px;
    
}

.container * {
    /* transition: height .1s; */
}

.wrap {
    width: 100%;
    height: 100%;
    position: relative;
    /* transition: height .1s; */
    box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%);

}

.paddingWrap {
    width: 100%;
    height: 100%;
    padding-top: 20px;
}

.paddingWrap.scroll {
    padding-top: 0px;
}

.logo {
    /* width: 100%; */
    height: 25px;
}

.logoIcon {
    width: 100%;
    height: 100%;
}

.logoText {
    font-size: var(--font-size-xsmall);
    color: #999;
    text-align: center;
}

.sideMenuButton {
    /* border:1px solid #ddd; */
    padding: 10px;
    cursor: pointer;
    color:white;

}

.sideMenuButton.show {
    /* background-color: #ddd; */
    color: white;
}

.showList {
    background-color: white;
    position: fixed;
    top: 45px;
    left: 11%;
    width: 35%;
    border: 1px solid #ddd;
}

.showList.scroll {
    background-color: white;
    position: fixed;
    top: 70px;
    left: 2%;
    width: 35%;
}


.menu {
    font-size: 17px;
    font-weight: 700;
}

.menu.active {
    color: var(--main-color);
}


.listWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    gap: 15px;

}

.categoryIcon {
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 5%;
}

.categoryIcon.mobile {
    flex: 1 0 20%;
}

.image {
    width: 50px;
    height: 50px;
}

.categoryItem {
    cursor: pointer;
}

.columns {
    height: 100%;
    float: right;
}

.column {
    height: 100%;
    float: left;
    cursor: pointer;
}