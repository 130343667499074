.icon {
    width: 100%;
    height: 100%;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
}

.notification {
    position: absolute;
    top:-3px;
    right:-3px;
    width:7px;
    height:7px;
    background-color: var(--main-color);
    border-radius: 50%;
}