.container{
    background-color: white;
    height: 100%;
    
}
.table {
    width: 100%;
    border-collapse: collapse;
    padding : 20px ;
    background-color: white;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table th {
    background-color: #5471e6;
    color: white;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    
}

.button {
    background-color: #5471e6;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    border-radius: 5px;
}
