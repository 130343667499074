.fixed {
    position: fixed;
    bottom:0px;
    left:0px;
    height:var(--footerHeight);
    z-index: 3300;
    background-color: white;
    padding:8px 0px;
    border-top: 1px solid var(--line-color);
}

.footerIcon.active{
    color : red ;
}

.image {
    height:100%;
}

.desktopFooterWrap {
    height:100%;
    border-top : 1px solid #ddd;
    border-bottom : 1px solid #ddd;
}
