.searchBarArea {
    /* padding: 5px 10px; */
    position: sticky;
    bottom: 0;
    border:1px solid var(--main-color);;
}

.searchButtonArea {
    text-align: center;
    
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:100%;
}
.searchBar {
    overflow: hidden;
    background-color: white;
    border-radius: 50px;
}
.searchTextArea {
    padding:0px 20px;
}

.searchTextInput {
    width:100%;
    height:100%;
    background-color: transparent;
}