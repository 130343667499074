.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #383e58;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.container {
  background-color: white;
  height: 100%;
}

.table {
  width: 100%;
  border-collapse: collapse;
  padding: 20px;
  background-color: white;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #5471e6;
  color: white;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.button {
  background-color: #5471e6;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  border-radius: 5px;
}
.clickable:hover {
  cursor: pointer;
  text-decoration-line: underline;
}
.selected {
  text-decoration-line: underline;
}
