.dummyImg{
    width: 70px;
    background-color: red;
    border-radius: 50%;
}


.titleLine{
    width : 100% ;
    height: 2px;
    background-color: rgba(206, 206, 206, 1);
}

.line{
    width : 100% ;
    height: 1px;
    background-color: var(--line-color);
}

.wrap{
    padding : 10px ;
}

.headerContainer {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(206, 206, 206, 1);
    position: sticky;
    position: -webkit-sticky;
    top: 5px;
  }
  
  .step {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 150px; */
    height: 40px;
    position: relative;
    background-color: #2979ff; /* 파란색 */
    color: white;
    font-weight: bold;
    
  }
  
  .step::after {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
    height: 0;
    z-index: 9;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent #2979ff;
  }
  
  .inactiveStep {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 150px; */
    height: 40px;
    position: relative;
    background-color: white; 
    color: #888;
    font-weight: bold;
    
  }

  .step2 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 150px; */
    height: 40px;
    position: relative;
    background-color: white; 
    /* 파란색 */
    color: #888;
    font-weight: bold;
    
  }
  
  .step2::after {
    content: "";
    position: absolute;
    right: -20px;
    top: 0;
    width: 0;
    height: 0;
    z-index: 9;
    border-style: solid;
    border-width: 20px 0 20px 20px;
    border-color: transparent transparent transparent white;
  }
  
  .inactiveStep2 {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 150px; */
    height: 40px;
    position: relative;
    background-color: #2979ff;
    color: white;
    font-weight: bold;
    
  }