.wrap {}

.pageHeader {
    padding: 30px 0px;
    border-bottom: 1px solid lightgray;
    text-align: center;
}

.pageGuide {
    padding: 15px 0px;
    border-bottom: 1px solid black;
}

