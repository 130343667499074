.container {
    position: relative;
    height:100%;
}

.button {
    float: right;
    width: initial;
}

.dummy {
    width: 100%;
    height: 100%;
}

.navLink {
    color: black !important;
}

.navLink.active {
    color: var(--cui-nav-link-color) !important;
}

.stickyTop {
    position: sticky;
    top: 0px;
    height: 60px;
    background-color: white;
    z-index: 10;
    padding:10px 10px 0px 10px ;
}

.contentArea {
    padding:20px;
    height:100%;
}

.child {
    display:none;
    height:100%;
}

.child.active {
    display:block;
}

.bottom {
    display: none;
    position: fixed;
    bottom:0px;
    right:0px;
    padding:10px 20px;
    background-color: white;
}

.bottom.show {
    display: block;
}