.container {
  width: 1920px;
  padding-left: 20px;
  padding-right: 20px;
}

.container.mobile {
  width: 100%;
  padding-left: 0px;
  padding-right: 0px;
}

.toolbar {
  padding: 10px 10px 0px 10px;
}

.droppableElement {
  width: 200px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}

.box {
  width: 100%;
  height: 100%;
  box-shadow: 3px 3px 5px var(--box-shadow-color);
}
.box.mobile {
  width: 95% !important;
}

.box.draggable {
  border: 3px dashed #b9caf6;
}

.box.draggable > div {
  pointer-events: none;
}
