
.addressHeaderWrap{
    border-top : 2px solid #dcdcdc ;
    
    padding : 20px 0 ;
}

.fixedPaymentWrap{
    border: 1px solid #ddd;
     padding : 20px 10px ;
     background-color: white;
     
}

.line{
    width : 90% ;
    height: 1px;
    background-color: #ddd;
}

.agreeWrap{
    background-color: #f7f8f8;
    padding: 10px;
}


.borderBottom{
    border-bottom: 1px solid #ddd ;
    padding: 5px 5px 10px 5px ;
}

.tableBorder{
    border-top: 2px solid black;
    padding: 10px ;
}

.fixedWrap{
    position: relative;
}

.dummy{
    height : 50px ;
}

.couponWrap{
    background-color: #999;
    height: 45px;
}

.titleLine{
    width: 100% ;
    height: 2px;
    background-color: black;
}

.paymentWrap{
    padding : 20px ;
    background-color: #eee;
}

.paymentLine{
    border-right: 1px solid #999;
    
}

.verticalLine{
    width : 1px ;
    height: 146px;
    background-color: #999;
}

.longLine{
    width: 100%;
    height: 1px;
    background-color: #999;
}

.mobileAddressWrap{
    border: 1px solid #ddd;
    background-color: #f7f8f8;
    padding : 10px ;
    border-radius: 10px;
}

.newMobileAddressWrap{
    /* border: 1px solid #ddd; */
    background-color: #f7f8f8;
    padding : 20px 30px;
    border-radius: 10px;
}

.boldDivider {
    width: 100%;
    height: 3px;
    background-color: #CECECE;
    margin: 0 0;
  }