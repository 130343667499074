.arrowBox {
	position: relative;
    padding:10px;
	left:0;
    width:fit-content;
	background: #88b7d5;
	border-radius: 10px;
    z-index: 100;
}

.arrowBox:before {
	content: "";
	position: absolute;
	top:-45%;
	left:50%;
	border: solid transparent;
	pointer-events: none;
	border-bottom-color: #88b7d5;
	border-width: 10px;
	margin-left: -10px;
}
