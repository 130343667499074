.wrap {
    position:relative;
    background-color: white;
    padding: 0 10px;
}

.icon {
    width: 100%;
}

.displayTypeIcon {
    width: 100%;
    fill: #6f6f6f;
    color: #6f6f6f;
}

.displayTypeIcon.active {
    fill: var(--main-color);
    color: var(--main-color);
}

.listWrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
}
.listWrap.mobile {
    gap: 10px !important;
}
.listWrap.horizontalListCard{
    width: 100%;
    flex: 1 1 80% !important;
}

.listWrap.list {
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 30px;
    align-items: center;
    /* justify-content: center; */
}

.card {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 18%;
}

.card.mobile {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 37%;
}

.card:last-child {
    /* flex: 0 0 10%; */
}

