.container {
    position: relative;
    background-color: white;
}

.container.mobile {
    background-color: white;
}

.sticky {
    position: sticky;
    top:0;
    z-index: 1000;
}

.outlet {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 410px);
    padding-top: 0;
}

@media screen and (min-width: 769px) {
    .outlet {
        padding-top: 161px;
    }
}