.container{
    margin-top: 15px;
    font-size : 11px ;
    color : #666 ;
}

.link{
    text-decoration: underline;
    color : #666 ;
}

.link:hover{
    text-decoration: underline;
}

.safety{
    padding : 30px ;
    border: 1px solid #666;
    color : #666 ;
    margin-top: 20px;
    border-radius: 5px;
    font-size : 13px ;
    text-align: center;
}