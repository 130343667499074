.middle {
    position: relative;
    width: 50%;
    max-width: 500px;
  }
  
  .slider {
    position: relative;
    z-index: 1;
    height: 10px;
    margin: 0 15px;
  }
  
  .slider > .track {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #c6aee7;
  }
  
  .slider > .range {
    position: absolute;
    z-index: 2;
    left: 25%;
    right: 25%;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #6200ee;
  }
  
  .slider > .thumb {
    position: absolute;
    z-index: 3;
    width: 30px;
    height: 30px;
    background-color: #6200ee;
    border-radius: 50%;
  }
  
  .slider > .thumb.left {
    left: 25%;
    transform: translate(-15px, -10px);
  }
  .slider > .thumb.right {
    right: 25%;
    transform: translate(15px, -10px);
  }
  
  .input{
    position: absolute;
    /* opacity로 가린 것을 이벤트도 비활성화하기 위해 */
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 2;
    height: 10px;
    width: 100%;
    opacity: 0;
  }
  
  .input::-webkit-slider-thumb {
    /* 겹쳐진 두 thumb를 모두 활성화 */
    pointer-events: all;
    width: 30px;
    height: 30px;
    border-radius: 0;
    border: 0 none;
    background-color: red;
    cursor: pointer;
    /* appearance를 해야 위의 스타일들을 볼 수 있음 */
    -webkit-appearance: none;
  }
  