.wrap {
    padding: 2%;
}

.card {
    /* border: 1px solid #ddd; */
    background-color: white;
    cursor: pointer;
    /* box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%); */
}

.card:hover {
    box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%);
}

.thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image {
    width: 100%;
}

.beforePriceArea {
    height:100%;
    background-color: white;
}

.currentPriceArea {
    background-color: #62e0f3;
}

.priceTag {
    width: 90%;
    border-radius: 50px;
    overflow: hidden;
    border: 1px solid #62e0f3;
    background-color: #62e0f3;
}