.title{
    display: flex;
    justify-content: center;
    align-items: center;
    color : white;
    font-size: var(--font-size-lg) ;
    font-weight: bold;
    background-color: var(--main-color);
    height: 100px;
    cursor: pointer;
}
.mobileTitle{
    display: flex;
    justify-content: center;
    color : white;
    font-size: 16pt;
    font-weight: bold;
    background-color: var(--main-color);
    padding: 1vh 0 ;
}
.header{
    border-top: 2px solid var(--line-color-lg);
    border-bottom: 1px solid var(--line-color);
    padding:7px 8px;
    background-color: white;
}

.userWelcome{
    display: flex;
    font-size: 14pt;
}
.couponWrap{
    cursor: pointer;
}

.couponImg{
    width: 60%;
}
.pointWrap{
    cursor: pointer;
}

.pointImg{
    width: 50%;
}

.padding{
    
    padding: 15px 0;

    border-bottom: 1px solid #ddd;
}

.wrap{
    padding:10px;
    
}

.displayNone{
    display: none;
}

.verticalLine{
    width : 1px ;
    height: 30px;
    background-color: #999;
}

.horizontalLine{
    width : 100% ;
    height: 1px;
    background-color: #eee;
}

