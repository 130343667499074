.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
}

.pagination-btn,
.pagination-number {
    font-size: 12px;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;
    transition: background-color 0.3s ease;
}

.pagination-number.active {
    background-color: #007bff;
    /* 현재 페이지의 배경색 */
    color: #fff;
    /* 텍스트 색상 */
}

.pagination-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}