.wrap {
  padding: 60px;
  height: 75vh;
}
.box {
  overflow-y: auto;
  height: 60vh;
  min-height: 60vh;
}
.boxWrap {
  border: 1px solid #eee;
}

.listBox {
  border-bottom: 1px solid #eee;
  padding: 10px 20px;
  cursor: pointer;
}

.addCategoryWrap {
  background-color: #f5f6fb;
  padding: 20px;
}
.contentWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.circle {
  width: 7px;
  height: 7px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.categoryWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fb;
  padding: 10px 15px;
  gap: 10px;
  cursor: pointer;
}
.categoryWrap.selected {
  background-color: #383e58;
  color: white;
}
.line {
  height: 1px;
  border-bottom: 1px solid #eee;
  padding-top: 5px;
  padding-bottom: 5px;
}
.line2 {
  width: 1px;
  height: 100%;
  border-right: 1px solid #eee;
  padding-left: 5px;
  padding-right: 5px;
}
