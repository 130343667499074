.radio {
    display: none;
}

.radioButtionLabel {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radioButton {
    position: relative;
    vertical-align: top;
    width: 1rem;
    height: 1rem;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radioButton::before {
    content: '';
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background: #e5e9e9;
    border: 1px solid #a5a8a8;
    border-radius: 100%;
    cursor: pointer;
    transform: translateX(-50%);
    -webkit-box-shadow: inset 0 1px #fff;
    box-shadow: inset 0 1px #fff;
}

.radio:checked+.radioButton:before {
    border: 1px solid var(--main-color);
}

.radioButton::after {
    opacity: 0;
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    left: 0;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    background: #666666;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100%;
    cursor: pointer;
    box-shadow: 0 1px rgba(255, 255, 255, 0.50);
}



.radio:checked+.radioButton:after {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    opacity: 1;
}
