.wrap {
    position: relative;
    cursor: pointer;
    background-color: #00000080;
    box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%);
}

.wrap:hover {
    box-shadow: 5px 5px 5px 0px rgb(0 0 0 / 10%);
}

.card {
    background-color: white;
}
.titleArea{
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    
}

.thumbnail.horizontal {
   
}

.cardWrap {
    /* position: relative; */
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.priceArea {
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    overflow: hidden;
}

.image {
    width: 100%;
}

.image.horizontal {
    width:40%;
    
    transform: translateX(20%);
}

.beforePriceArea {
    background-color: white ;
}

.currentPriceArea {
    background-color: var(--main-color);
}

.priceTag {
    width:90%;
    border-radius: 50px;
    overflow: hidden;
    border:1px solid var(--main-color);
    background-color: var(--main-color);
}
