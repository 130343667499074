.mask {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  /* background-color: rgba(0, 0, 0, 0.7); */
  z-index: 10000;
  overflow: auto;
  animation: modal-bg-show 0.3s;
}

.wrap {
  display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
}

.modal {
  position: fixed;
  /* border-radius: 10px; */
  /* text-align: center; */
  background-color: white;
  word-break: break-all;
  animation: modal-show 0.3s;
  height: 100%;
  overflow: hidden;
}

.slideUpModal {
  position: fixed;
  bottom: 0;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  /* text-align: center; */
  background-color: white;
  word-break: break-all;
  animation: slideUp 0.3s forwards;

  height: 100%;
  overflow: hidden;
}
.modalSlideDown {
  animation: slideDown 0.3s forwards;
}

.bgHeader {
  background-color: #5471e6;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #dee2e6;
  height: 100%;
}

.header {
  background-color: #f1f1f1;
  font-weight: 700;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 1px solid #dee2e6;
  height: 100%;
}

.headerCloseButton {
  width: 100%;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: 0;
}

.main {
  height: 100%;
}

.footer {
  position: absolute;
  height: 50px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  background: #f1f1f1;
  border-top: 1px solid #dee2e6;
  text-align: right;
}

.footer > button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border: 0;
}

@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }

  to {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}
