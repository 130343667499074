.contentArea {
    position: relative;
    display: flex;
    gap: 24px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.sidePanel {
    width: max(20%, 350px);
    height: 100%;
    position: sticky;
    top: 100px;
}

.shippingInfoArea {
    border: 1px solid #ddd;
    cursor: pointer;
    border-radius: 8px;
    padding: 16px;
    background: white;
}

.paymentMethodArea {
    border: 1px solid #ddd;
    cursor: pointer;
    padding: 16px;
    border-radius: 8px;
    background: white;
}

.paymentMethodIcon {
    width: 80px;
    height: auto;
}

.orderListContainer {
    padding: 16px;
    border: 1px solid #ddd;
    background-color: white;
    border-radius: 8px;
    margin-bottom: 24px;
}

.addressHeaderWrap {
    border-top: 2px solid black;
    background-color: #f8f9fa;
    padding: 20px 16px;
}

.fixedPaymentWrap {
    border: 1px solid #ddd;
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fixedPaymentWrap.fixed {
    position: sticky;
    height: 450px;
    width: 300px;
    top: 80px;
}

.line {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 16px 0;
}

.titleLine {
    width: 100%;
    height: 2px;
    background-color: black;
    margin: 16px 0;
}

.paymentWrap {
    padding: 20px;
    background-color: #eee;
}

.paymentLine {
    border-right: 1px solid #999;

}

.verticalLine {
    width: 1px;
    height: 146px;
    background-color: #999;
}

.longLine {
    width: 100%;
    height: 1px;
    background-color: #999;
}

.agreeWrap {
    background-color: #f7f8f8;
    padding: 10px;
}


.borderBottom {
    border-bottom: 1px solid #ddd;
    padding: 5px 5px 10px 5px;
}

.tableBorder {
    border-top: 2px solid black;
    padding: 10px;
}

.fixedWrap {
    position: relative;
}

.dummy {
    height: 50px;
}

.couponWrap {
    background-color: #999;
    height: 45px;
}

@media (max-width: 768px) {
    .contentArea {
        flex-direction: column;
        padding: 16px;
    }

    .sidePanel {
        width: 100%;
        position: static;
    }

    .fixedPaymentWrap {
        position: static;
        width: 100%;
        height: auto;
        padding: 16px;
    }
}