.container {
    width: 100%;
    background-color: white;
}

.register {
    padding: 30px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: row;
}

.chatBox {
    /* box-shadow:0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12); */
    /* margin:40px 50px; */
    height: 600px;
    padding: 10px;
    display: flex;
    flex-direction: row;
}

.memberList {
    width: 100%;
}

.chatContent {
    width: 100%;
    margin-left: 10px;
}

.chatMessages {
    overflow-y: scroll;
    height: 50vh;
    border: 1px solid #000;
}

.sendMessage {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.inputMessage {
    width: 70%;
    border-radius: 50px;
}

ul {
    padding: 0;
    list-style-type: none;
}

.sendButton {
    width: 30%;
    border-radius: 50px;
    margin-left: 5px;
    cursor: pointer;
}

.member {
    padding: 10px;
    background: #eee;
    border: #000;
    cursor: pointer;
    margin: 5px 2px;
    /* box-shadow: 0 8px 8px -4px lightblue; */
}

.member.active {
    background: blueviolet;
    color: #fff;
}

.member:hover {
    background: grey;
    color: #fff;
}

.avatar {
    width: max-content;
    white-space: nowrap;
    height: 100%;
    background-color: cornflowerblue;
    padding: 3px 5px;
    border-radius: 5px;
    color: #fff;
}

.avatar.self {
    color: #000;
    background-color: greenyellow;
}

.message {
    padding: 5px;
    width: auto;
    display: flex;
    flex-direction: row;
    /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
    margin: 5px 10px;
}

.messageData {
    padding: 5px;
}

.messageData.self {
    text-align: right;
}
.message.self {
    justify-content: end;
}