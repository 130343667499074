.container {
  padding: 10px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #383e58;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.container {
  background-color: white;
  height: 100%;
}

.contentWrap {
  padding: 30px;
}

.line {
  height: 1px;
  border-bottom: 1px solid #eee;
}

.select {
  width: 100%;
  height: 40px;
}

.radioWrap {
  background-color: #f5f6fb;
  padding: 15px;
}

.categoryWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f6fb;
  padding: 10px 15px;
  gap: 10px;
  cursor: pointer;
}
.categoryWrap.selected {
  background-color: #383e58;
  color: white;
  border: 2px solid #383e58;
}

.circle {
  width: 5px;
  height: 5px;
  background-color: black;
  border-radius: 50%;
}

.categoryModalBtn {
  width: max-content;
  border: 1px solid #aaa;
  padding: 5px 10px;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}

.input {
  outline: none;
  background-color: rgb(233, 233, 233);
  border-radius: 10px;
  margin-left: auto;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 250px;
}
