/* 검색 아이콘만 오른쪽으로 이동 */
.searchIcon {
    font-size: var(--font-size-2xlarge);
    cursor: pointer;
    transition: color 0.3s ease;
    position: relative;
    top: 10px;
    z-index: 1002;
    transform: translateX(-10px);
  }
  
  .searchIcon:hover {
    color: var(--main-color);
  }
  .column {
    height: 100%;
    float: left;
}

  
/* 검색 input 기본 상태: 오른쪽에서 숨김 */
.searchInput {
    position: absolute; /* 아이콘과 겹치지 않게 배치 */
    right: 0; /* 오른쪽 끝에 정렬 */
    top: 20px; /* 수직 중앙 정렬 */
    transform: translateY(-50%) translateX(-250%);
    transform-origin: right; /* 애니메이션 시작점을 오른쪽으로 설정 */
    width: 0;
    padding: 8px 10px;
    border: 1px solid transparent;
    border-radius: 5px;
    outline: none;
    /* transition: width 0.4s ease, transform 0.4s ease, border 0.4s ease; */
    opacity: 1;
    background-color: white;
    z-index: 1001; /* input이 아이콘 뒤에 가도록 설정 */
  }

  
  /* 활성화 상태: input이 부드럽게 등장 */
  .searchInput.active {
    width: 200px;
    transform: translateY(-50%) translateX(0);
    opacity: 1;
    border: 1px solid rgba(201, 202, 202, 1);
    border-radius: 5px;
    z-index: 1001;
  }
  
  .topBarItem {
    display: flex;
    align-items: center;
    position: relative;
    font-size: var(--font-size-small);
    padding: 0px 10px;
    z-index: 1001;
    cursor: pointer;
  }
  