.container{
    padding : 20px 30px ;
    background-color: white;
    border-radius: 10px;
}

.title{
    font-weight: bold;
    font-size : 22px ;
    padding-bottom: 25px;
}

.code{
    border: 1px solid #ddd;
    border-radius: 5px;
    height: 30px;
    width : 180px ;
}

.couponRow {
    display: inline-block;
    font-size : 14px ;
    padding-bottom: 10px;
}
.couponRow > *  {
    display: inline-block;
}

.codeTitle{
    font-size: 14px;
    padding-bottom: 5px;
}

.codeWrap{
    display: flex;
    color : red ;
}

.codeButton{
    font-size: 12px;
    font-weight: bold;
    width : 90px ;
    text-align: center;
    line-height: 30px;
    border: 1px solid red;
    border-radius: 5px;
    margin-left: 15px;
}

.border{
    height: 1px;
    background-color: #eee;
    margin: 20px 0 ;
}

.price{
    font-weight: bold;
    font-size: 24px;
}

.priceTitle{
    font-size : 14px ;
    font-weight: bold;
}

.button{
    width : 100% ;
    height: 40px;
    border: 1px solid red;
    border-radius: 5px;
    background-color: #FF4747 ;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color : white ;
}