.container {
  width: 100%;
}

.title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 24px;
  color: #111;
}

.productList {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.boldDivider {
  width: 100%;
  height: 3px;
  background-color: #CECECE;
  margin: 0 0;
}


@media (max-width: 768px) {
  .title {
      font-size: 20px;
      margin-bottom: 16px;
  }
  .productList {
      gap: 16px;
  }
}