.selectWrapper {
    position: relative;
    display: inline-block;
    width: 100%; /* 원하는 너비 설정 */
    font-family: "Arial", sans-serif;
  }
  
  .customSelect {
    appearance: none; /* 기본 select 디자인 제거 */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 30px 10px 15px;
    font-size: 16px;
    color: #aaa; /* 텍스트 색상 */
    cursor: pointer;
    width: 100%;
    outline: none;
  }
  
  /* 화살표 커스터마이징 */
  .customSelect::after {
    content: "\25BC"; /* 화살표 유니코드 */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #aaa;
  }
  
  .customSelect:hover {
    border-color: #bbb;
  }
  
  .customSelect:focus {
    border-color: #888;
    border: none;
    border-radius: none ;
  }
  