.newCardWrap{
    width : 95% ;
    height: 50px;
    padding-left: 15px;
    margin-top: 20px;
    margin-left : 15px ;
    border: 1px solid #ccc;
    display: flex;
    line-height: 45px;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    color : black ;
    
}


.content{
    padding-left  : 15px ;
    display: flex;
    
}