.container {
    width:100%;
    height:100%;
    border:1px solid blue;
}

.label {
    display:flex;
    align-items: center;
    width:100%;
    height:100%;
    background-color: #383E58;
    color: white;
    padding : 30px 10px ;
}

.contentArea{
    background-color: white;
    height: 100%;
    padding : 10px ; 
    
}
.line{
    height: 1px;
    border: 1px solid #eee;
}