.label{
    display: flex;
    align-items : center;
    justify-content: center;
    width:100%;
    height:100%;
    background-color: #383E58;
    color: white;
    padding : 10px 15px; 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.container{
    background-color: white;
    height: 100%;
    
}

.contentWrap{
    padding : 30px ;
}
.line{
    height: 1px ;
    border-bottom: 1px solid  #eee;
}

.select{
    width : 100% ;
    height: 40px ;
}



.switch {
    position: relative;
    display: inline-block;
    width: 115px;
    height: 24px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 24px;  /* round effect added here */
    padding-left : 25px ;
    color : #909090 ;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;  /* round effect added here */
    transform: translateX(2px);
    
  }
  
  
  input:checked + .slider {
    background-color: #5471e6;
    color : white ;
  }
  
  input:checked + .slider:before {
    transition: 0.4s;
    transform: translateX(90px);
  }

  .addOption{
    background-color: #eee;
    padding : 15px ;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px ;
    cursor: pointer;
  }


  .plus{
    width : 15px ;
    height: 15px;
    background-color: #5471e6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color : white ;
    
  }