.mask {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10000;
    animation: modal-bg-show 0.3s;
}

.maskBackground {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
}

.wrap {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100vh;
}

.modal {
    position: fixed;
    background-color: white;
    word-break: break-all;
    animation: modal-show 0.3s;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}



.slideUpModal {
    position: fixed;
    bottom: 0;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    background-color: white;
    word-break: break-all;
    animation: slideUp 0.3s forwards;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.modalContent {
    flex: 1;
    overflow-y: auto;
    overscroll-behavior-y: contain;
    -webkit-overflow-scrolling: touch;
    position: relative;
}

.modalContent{
    -ms-overflow-style: none;
 }
 .modalContent::-webkit-scrollbar{
   display:none;
 }

.modalSlideDown {
    animation: slideDown 0.3s forwards;
}

.mobileHeader {
    position: sticky;
    padding: 1rem;
    top: 0;
    background-color: white;
    font-weight: 700;
    border-radius: 10px 10px 0px 0px;
    z-index: 1;
}

.footer {
    position: sticky;
    background-color: white;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #dee2e6;
    text-align: right;
    z-index: 1;
}

.headerCloseButton {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    border: 0;
}

.main {
    height: 100%;
}

.closeButton {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    font-size: 21px;
    font-weight: 700;
    text-align: center;
    color: #999;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    border: 0;
}

@keyframes modal-show {
    from {
        opacity: 0;
        margin-top: -50px;
    }
    to {
        opacity: 1;
        margin-top: 0;
    }
}

@keyframes modal-bg-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}