.label{
    display: flex;
    align-items : center;
    justify-content: center;
    width:100%;
    height:100%;
    background-color: #383E58;
    color: white;
    padding : 10px 15px; 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.container{
    background-color: white;
    height: 100%;
    
}

.contentWrap{
    padding : 30px ;
}
.line{
    height: 1px ;
    border-bottom: 1px solid  #eee;
}

.select{
    width : 100% ;
    height: 40px ;
}