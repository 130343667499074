
  .tag{
    pointer-events: none;
    background-color: #242424;
    color: white;
    padding: 6px;
    margin: 5px;
    height: 38px;
    
  }
  
  .tag::before {
    pointer-events: all;
    display: inline-block;
    content: 'x';  
    height: 20px;
    width: 20px;
    margin-right: 6px;
    text-align: center;
    color: #ccc;
    background-color: #111;
    cursor: pointer;
  }