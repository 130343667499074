.eventContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.eventTagContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}

.mobileEventTagNPlusM {
    background-color: rgba(255, 55, 55, 1); /* 빨간색 */
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    font-size: 8px;
    font-weight: bold;
}

.mobileEventTagCross {
    background-color: rgba(41, 106, 255, 1); /* 연두색 */
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    font-size: 8px;
    font-weight: bold;
}

.mobileEventTagNoCross {
    background-color: rgba(41, 106, 255, 1); /* 핑크색 */
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    font-size: 8px;
    font-weight: bold;
}

.eventTagNPlusM {
    background-color: rgba(255, 55, 55, 1); /* 빨간색 */
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
}

.eventTagCross {
    background-color: rgba(41, 106, 255, 1); /* 연두색 */
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
}

.eventTagNoCross {
    background-color: rgba(41, 106, 255, 1); /* 핑크색 */
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
}

.noEvent {
    color: #999;
    font-style: italic;
    text-align: center;
    padding: 10px;
}

.discount{
    background-color: rgba(122, 107, 229, 1);
    color: #fff;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bold;
}

.mobileDiscount{
    background-color: rgba(122, 107, 229, 1);
    color: #fff;
    padding: 3px 8px;
    border-radius: 10px;
    font-size: 8px;
    font-weight: bold;
}