.container {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 20px;
}

.totalUserArea{
    background-color: #eee;
    padding : 10px 20px ;
}

.iconArea{
    background-color: #eee;
    width: max-content;
    padding : 10px ;
}

.boxWrap{
    border : 1px solid black ;
    padding : 15% ;
}