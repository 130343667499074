.cropContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
}

.slider {
    padding: 22px 0px;
}

.zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
}

.zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.output {
    margin:auto;
    overflow: hidden;
    box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.output img {
    position: relative;
    top: 0;
    left: 0;
    transform-origin: top left;
}

.viewer {
    position:absolute;
    top:0;
    left:0;
    width:fit-content !important;
    height:fit-content !important;
}

.croppedImage {
    border: 1px solid black;
}