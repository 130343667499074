.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #383e58;
  color: white;
  padding: 10px 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.container {
  background-color: white;
  height: 100%;
  padding: 10px;
}

.table {
  width: 100%;
  border-collapse: collapse;
  padding: 20px;
  background-color: white;
}

.table th,
.table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.table th {
  background-color: #5471e6;
  color: white;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
}

.button {
  background-color: #5471e6;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  border-radius: 5px;
}

.url:hover {
  text-decoration: underline;
}

.clickable:hover {
  cursor: pointer;
  text-decoration-line: underline;
}

.avatar {
  border-width: 1px;
  border-color: #a0a0a0;
  background-color: white;
  border-style: solid;
  border-radius: 100%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar.small {
  width: 1.5rem;
  height: 1.5rem;
}
.rooms:hover .avatar {
  /* background-color: #c0c0c0; */
  /* border-color: #a0a0a0; */
  background-color: #f0f0f0;
}
.rooms {
  padding: 15px;
  cursor: pointer;
}
.rooms:hover {
  background-color: #f0f0f0;
  box-shadow: 1px 1px 5px #666;
}
.rooms.selected {
  border-color: #a0a0a0;
  border-width: 1px;
  border-style: solid;
  box-shadow: 1px 1px 5px #666;
}
.chat {
  background-color: white;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
  min-width: 200px;
}
