.navigationButton {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    color: #4F4F4F;
    background-color: #F5F5F5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.navigationButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.navigationButton:hover:not(:disabled) {
    background-color: #E5E5E5;
}

.articleItem {
    padding: 16px;
    cursor: pointer;
    border-bottom: 1px solid #F0F0F0;
    transition: background-color 0.2s;
}

.articleItem:hover {
    background-color: #F5F5F5;
}

.currentArticle {
    background-color: #F5F5F5;
}

.articleTitle {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba(79,79,79,0.2);
}

.articleList {
    margin-top: 24px;
}