.header {
    position: fixed;
    top:0;
    z-index:  1000;
}

.footer {
    position:fixed;
    left:50%;
    bottom:0;
    z-index: 4000;
    transform: translateX(-50%);
}