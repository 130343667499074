.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .linkContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: 100%;
  }
  
  .copyButton {
    padding: 4px;
    border-radius: 4px;
    background: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .copyButton:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .icon {
    width: 16px;
    height: 16px;
  }
  
  .linkText {
    word-break: break-all;
  }