.container {
    position: relative;
}

.titleWrap {
    background-color: white;
    border-bottom: 1px solid #e0e0e0;
}

.deleteAllButton {
    cursor: pointer;
    width:initial;
    background-color: #eee;
    padding: 5px 15px ;
    color : #999 ;
}

.deleteAllButton:hover {
    color: var(--main-color);
    
}

.productWrap {
    background-color: white;
    margin-bottom: 10px;
    /* border: 1px solid #ddd; */
    /* padding: 10px 10px 0 10px; */
}

.summaryWrap {
    padding: 30px 100px;
    border-top: 1px solid #ddd ;
    
}

.purchaseButton {
    color: white;
    height: 50px;
    background-color: #f27370;
    cursor: pointer;
}

.footer {
    padding: 5px;
    background-color: white;
}



.buttonArea {}

.total {
    font-weight: bold;
    font-size: 15px;
}

.price {
    font-weight: bold;
    font-size: 15px;
}

.footerOverlay {
    position: absolute;
    top: 0;
    height: initial;
    transform: translateY(-100%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
    box-shadow: 0px -100vh 0px 100vh rgb(0 0 0 / 10%);
}

.circle{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 15px;
    position: relative;
}
.mobileCircle{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 11px;
    position: relative;
}

.topMinusLine{
    width:2px;
    height: 30px;
    background-color: #ddd;
    position: absolute;
    top: -200% ;
}

.bottomMinusLine{
    width:2px;
    height: 30px;
    background-color: #ddd;
    position: absolute;
    bottom: -225% ;
}

.totalBar{
    background-color: #eee;
    padding: 10px 20px ;
}

.horizontalLine{
    width: 90%;
    height: 1px;
    background-color: #ddd;
    position: relative;
    
}

.circleWrap{
    position: absolute;
    top : -8px ;
    
}