.container{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
}

.label{
    display: flex;
    align-items : center;
    justify-content: center;
    width:100%;
    height:100%;
    background-color: #383E58;
    color: white;
    padding : 10px; 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.progressBarArea{
    padding : 20px ;
}

.horizontalLine{
    height: 2px;
    background-color: black;
}
.verticalLine{
    height: 25px;
    width: 2px;
    background-color: black;
    
}

.boxArea{
    border: 1px solid #383E58;
    padding : 15px ;
    position: relative;
}
.boxWrap{
    padding : 10px ;    
}