.container {
    background-color: white;
  }
  
  .container h2 {
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .priceBreakdown {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  }
  
  .priceRow {
    display: flex;
    justify-content: space-between;
  }
  
  .divider {
    width: 100%;
    height: 1px;
    background-color: #ddd;
    margin: 10px 0;
  }
  
  .totalAmount {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding: 10px 15px ;
  }
  
  .totalPrice {
    color: var(--main-color);
  }

  .summaryWrap {
    padding: 30px 100px;
    border-top: 1px solid #ddd ;
    
}

.checkWrap {
  padding: 20px 20px;
  border-top: 3px solid #CECECE ;
  background-color: #F5F5F5;
  
}