.paragraph {
    transition: color 0.1s ease;
  }
  
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  /* hover 효과를 여기서 정의하고, enableHover=true 일 때만 이 클래스를 사용한다 */
  .hoverEnabled:hover {
    color: rgba(41, 105, 255, 1) !important;
  }
  
  /* 선택된 상태일 때 */
  .selected {
    color: rgba(41, 105, 255, 1) !important;
    font-weight: bold;
  }

  .textHover:hover{
    text-decoration: underline;
  }