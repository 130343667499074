.image {
    width:100%;
}

.modalContent{
    -ms-overflow-style: none;
 }

.modalContent::-webkit-scrollbar{
    display:none;
  }
