.container {
 background-color: white;
 box-shadow: 3px 3px 3px 0px rgb(0 0 0 / 10%);
}

.buttonArea {
    text-align: center;
    height: initial;
    font-size: 20px;
}

.searchBarArea {
    padding: 5px 10px;
}

.searchButtonArea {
    text-align: center;
    background-color: #e62e2e;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchBar {
    overflow: hidden;
    background-color: white;
    border-radius: 50px;
    
}
.searchTextArea {
    padding:0px 20px;
    
}

.searchTextInput {
    width:100%;
    height:100%;
    background-color: transparent;
}
.logoIcon {
    fill: var(--main-color);
    width:100%;
}

.back{
    cursor: pointer;
}

.menuButton {
    cursor:pointer;
}