.wrap {
    /* width:100%; */
    cursor: pointer;
}

.sildeImg {
    width: 100%;
    max-width:1300px;
}

.swiperContainer{
    position: relative;
}