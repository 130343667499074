.cart {
    /* transform: translateX(100%); */
}

.icon {
    position: relative;
    width:initial;
    height:initial;
    padding:10px;
    color:var(--main-color);
}

.counter {
    position:absolute;
    top:0;
    right:0;
    background-color: var(--main-color);
    color:white;
    width:7px;
    height:7px;
    text-align: center;
    border-radius: 50%;
    font-size: var(--font-size-small);
}