.container {
    padding:10px 10px 10px 0px;
}

.searchBar {
    overflow: hidden;
    background-color: var(--searchBar-color);
    /* padding:3px; */
}

.inputArea {
    padding:0px 10px;
}

.inputText {
    width:100%;
    height: 100%;
    background-color: transparent;
}

.button {
    height:36px;
    background-color: var(--main-color);
}

.keywordList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: flex-start;
    gap:10px;
}

.keyword {
    width:fit-content;
    height:30px;
    padding:5px 10px;
    background-color: #eee;
}

