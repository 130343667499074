.wrap{
    background-color: white;
    padding : 10px ;
    height: 100% ;
    box-shadow: 3px 3px 5px var(--box-shadow-color);
    border-radius: 5px;
}

.line{
    width : 100% ;
    height : 1px ;
    border-bottom: 1px solid #eee;
}

.text{
    padding : 2px ;
}

.boxArea{
    background-color: #eee;
    padding : 10px ;
}