.container{
    width: 100%;
    height: 100%;
    background-color: white;
    border-radius: 5px;
}

.label{
    padding : 15px ;
    
}


.table {
    width: 100%;
    border-collapse: collapse;
}

.thead {
    background-color: #f0f0f0; 
    border-bottom: 1px solid #5471e6;
}

.tr{
    border-bottom: 1px solid #eee;
}

.td,
.th {
    
    padding: 8px;
    text-align: left;
    
}

.tdLabel{
    
    width: max-content;
    padding : 12px ;
    height: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px solid black;
    
    
}
.specialTdLabel{
    background-color: #eee;
    border: 1px solid transparent;
}

.specialRank{
    color: #5471e6 ;
}