.productRow {
    position: relative;
    background: white;
    border-radius: 8px;
    padding: 24px 0;
    display: grid;
    grid-template-columns: 200px 1fr;
    gap: 24px;
}

.thumbnail {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
    grid-row: 1;
}

.contentArea {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-column: 2;
}

.titleSection {
    padding-right: 16px;
    margin-bottom: 16px;
}

.titleMain {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
}

.titleInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 0;
}

.productTitle {
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
    /* flex: 1; */
    min-width: 0;
    overflow-wrap: break-word;
    word-break: break-all;
}

.eventTag {
    display: inline-flex;
    padding: 4px 8px;
    background-color: #4A7DFF;
    color: white;
    border-radius: 4px;
    font-size: 14px;
    width: fit-content;
}

.totalAmount {
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    font-size: 18px;
    font-weight: bold;
    color: #111;
}

.variantsList {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-right: 24px;
}

.variantItem {
    display: grid;
    grid-template-columns: 2fr 120px 100px 24px;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.variantDescription {
    font-size: 16px;
    color: #737373;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.freeTag {
    display: inline-block;
    padding: 2px 6px;
    background-color: #2ecc71;
    color: white;
    border-radius: 4px;
    font-size: 12px;
    margin-left: 8px;
}

.quantityControl {
    display: flex;
    width: fit-content;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: white;
}

.quantityButton {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.quantityButton:hover {
    background-color: #f8f9fa;
}

.quantityButton.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.quantity {
    width: 45px;
    text-align: center;
}

.quantityInput.validating {
    opacity: 0.7;
    background-color: #f5f5f5;
  }

.deleteButton {
    width: 20px;
    height: 20px;
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.2s;
}

.deleteButton:hover {
    opacity: 1;
}

.addEventItemButton {
    width: 100%;
    padding: 12px 16px;
    background-color: #f8f9fa;
    /* border: 1px dashed #4A7DFF; */
    border: none;
    border-radius: 4px;
    /* color: #4A7DFF; */
    color: #737373;
    /* font-weight: 500; */
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addEventItemButton svg {
    margin-right: 5px;
    transform: rotate(0deg);
    transition: transform 0.2s;
}

.addEventItemButton.open svg {
    transform: rotate(180deg);
}

.addEventItemButton:hover {
    background-color: #edf2ff;
}

.eventDropdown {
    width: 100%;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-top: 4px;
}

.eventDropdownItem {
    padding: 12px 16px;
    color: #495057;
    cursor: pointer;
    transition: background-color 0.2s;
}

.eventDropdownItem:hover {
    background-color: #f8f9fa;
}

@media (max-width: 768px) {
    .productRow {
        display: flex;
        flex-direction: column;
        /* padding: 16px; */
        gap: 16px;
    }

    .topSection {
        display: flex;
        gap: 12px;
    }

    .thumbnail {
        width: 100px;
        height: 100px;
        flex-shrink: 0;
    }

    .contentArea {
        flex: 1;
        min-width: 0;
    }

    .titleSection {
        padding-right: 0;
        margin-bottom: 0;
    }

    .titleMain {
        flex-direction: column;
        gap: 4px;
    }

    .titleInfo {
        gap: 4px;
    }

    .productTitle {
        font-size: 16px;
        line-height: 1.3;
    }

    .eventTag {
        font-size: 12px;
        padding: 2px 6px;
    }

    .totalAmount {
        justify-content: flex-start;
        font-size: 16px;
    }

    .variantsList {
        padding-right: 0;
    }

    .variantItem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px;
        gap: 12px;
    }

    .variantDescription {
        flex: 1;
        min-width: 0;
    }

    .quantityControl {
        margin-left: auto;
    }

    .deleteButton {
        margin-left: 8px;
    }
}