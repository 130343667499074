.wrap {
    display : flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: max-content;
}

.label {
    display: inline-block;    
}

.checkbox {
    height:30px;
    background-color: #ffffff;
    
}

input[type=checkbox] {
    display: none;
    
  }

.checkboxIcon{
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    background-color: transparent;
    border: 1px solid rgb(102,102,102);
    position: relative;
    cursor: pointer;
}
.checkboxIcon::before, .checkboxIcon::after{
    content : '' ;
    display: inline-block;
    width : 1px ;
    height: 0;
    background-color: rgb(102,102,102);
    position: absolute ;
    transform-origin: left top;
    
}
.checkboxIcon::before{
    top: 10px ;
    left : 2px ;
    transform: rotate(-25deg);
}

.checkboxIcon::after{
    top : 28px ;
    left : 12px ;
    transform: rotate(-145deg);
}
.checkbox input:checked + .checkboxIcon {
    border-color: rgb(102,102,102);
  }

.checkbox input:checked + .checkboxIcon::before{
    width: 3px;
    height: 20px;
    border-radius: 10px;
    background-color: var(--admin-main-color);
    transition: all 0.15s ease;
}
.checkbox input:checked + .checkboxIcon::after{
    width: 3px;
    height: 27px;
    border-radius: 10px;
    background-color: var(--admin-main-color);
    transition: all 0.15s ease 0.15s ;
}

.checkboxText{
    padding-left : 10px;
}