.container {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 5px;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #383e58;
  color: white;
  padding: 10px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.contentArea {
  padding: 20px;
  overflow-y: scroll;
}

.titleArea {
  background-color: #eee;
  padding: 10px;
  height: 80vh;
}

.deleteIconArea {
  background-color: #383e58;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
}

.managementArea {
  background-color: white;
  border: 1px solid black;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 3px;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead {
  background-color: #f0f0f0; // 원하는 색상으로 변경
}

.tr {
  &:nth-child(even) {
    background-color: #ffffff;
  }

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
}
.tr_e {
  &:nth-child(even) {
    background-color: #ffffff;
  }

  &:nth-child(odd) {
    background-color: #f9f9f9;
  }
}
.tr:hover {
  cursor: pointer;
  text-decoration: underline;
}

.td,
.th {
  // border: 1px solid #dddddd;
  padding: 8px;
  text-align: left;
}

.tdLabel {
  color: white;
  width: 80%;
  height: 20px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
